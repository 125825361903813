import request from '@/utils/request'

const apis = {
  cart: '/drinks/app/v1/cart',
  delCart: '/drinks/app/v1/cart/sub',
  getPrice: '/drinks/app/v1/cart/price'
}

export const getShopCart = () => {
  return request({
    url: apis.cart,
    method: 'GET'
  })
}

export const addShop = (params) => {
  return request({
    url: apis.cart,
    method: 'POST',
    params
  })
}

export const subShop = (params) => {
  return request({
    method: 'DELETE',
    url: apis.delCart + '/' + params.skuId
  })
}

export const delShop = (data) => {
  return request({
    method: 'DELETE',
    url: apis.delCart,
    data
  })
}

export const getAllPrice = (data) => {
  return request({
    method: 'POST',
    url: apis.getPrice,
    data
  })
}
